<template>
  <div>
    <router-link :to="{ name: 'admin.academy.courses.create' }" class="btn btn-info btn-sm float-right">Add Course</router-link>
    <h5>Courses</h5>
    <div class="row" v-if="! loading">
      <div
        class="col-md-4"
        v-for="(course, i) in courses"
        :key="`course-${i}`"
      >
        <div class="card">
          <router-link :to="{ name: 'admin.academy.courses.show', params: { course_id: course.id }}">
            <video-thumbnail :video="course.thumbnail" />
          </router-link>
          <div class="card-body">
            <h6>
              <router-link :to="{ name: 'admin.academy.courses.show', params: { course_id: course.id }}"
                ><strong
                  >#{{ course.sort }} {{ course.title }}</strong
                ></router-link
              >
            </h6>
            <div class="row text-center">
              <div class="col-6">
                <span class="d-block">Weeks</span>
                <span>{{ course.weeks }}</span>
              </div>
              <div class="col-6">
                <span class="d-block">Videos</span>
                <span>{{ course.videos }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      loading: true,
      courses: []
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get(`/api/v1/admin/academy/${this.$route.params.id}/courses`).then(response => {
        this.courses = response.data.courses
        this.loading = false
      })
    }
  }
}
</script>